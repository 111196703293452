@import "../shared/variables.scss";
@import "../shared/core.scss";

@include button;

.flexCenterXY {
    align-items: center;
}

.freePlayImg {
    position: absolute;
    height: 55%;
    margin: auto;
    top: 5%;
    left: 5%;
    z-index: 4;
}

.band {
    padding: $spacing-md 0;

    @media (min-width: $screen-lg) {
        padding: $spacing-lg 0;
    }

    &.small {
        padding: $spacing-sm 0;
    }

    &.large {
        padding: $spacing-lg 0;
    }
}

.pageHome {

    p {
        color: $white;
    }
    // Hero section
    .heroBackground {
        /*@include image-background('../images/template/heroWelcome.png');*/
        position: relative;
        min-width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
        min-height: fit-content;
        overflow: hidden;

        .overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            background: black;
            opacity: 0;
        }

        .video {
            position: absolute;
            /* width: auto; */
            height: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            /* height: 100%; */
            object-fit: cover;
        }

        .hero {
            position: relative;
            min-width: 100vw;
            height: fit-content;
            display: flex;
            justify-content: start;
            align-items: start;
            justify-content: center;
            align-items: center;
            padding: 0;
        }

        .carouselItem {
            height: min(650px, 50vw);
            min-height: 400px;
            object-fit: cover;
            width: 100%;
            /*@media (min-width: $screen-sm) {
                height: 525px;
            }

            @media (min-width: $screen-md) {
                height: 550px;
            }

            @media (min-width: $screen-lg) {
                height: 650px;
            }*/

            img {
                width: 100%;
                object-fit: cover;
                display: block;
                margin: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                object-position: right;
            }
        }

        .heroContainer {
            position: absolute;
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            z-index: 3;
            bottom: 3vw;
            //right: 5vw;

            @media (min-width: $screen-xs) {
                width: 90%;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            @media (min-width: $screen-sm) {
                width: 90%;
            }

            @media (min-width: $screen-md) {
                width: 70%;
                align-items: start;
                justify-content: start;
                text-align: start;
            }

            @media (min-width: $screen-lg) {
                width: 60%;
            }

            @media (min-width: $screen-xl) {
                width: 40%;
            }

            .heroRow {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                width: 100%;
                margin: 0;
            }

            p {
                font-size: 25px;
            }
        }
    }

    .heroHeading {
        position: relative;
        z-index: 100;
        max-width: 280px;
        height: auto;

        @media (min-width: $screen-md) {
            max-width: 300px;
        }

        @media (min-width: $screen-lg) {
            max-width: 400px;
        }
    }

    .newWayToPlaySection {
        @include image-background('../images/woodBg.png');

        h2 {
            text-align: center;
        }

        .playCardWrapper {

            @media (max-width: $screen-md) {
                margin-bottom: $spacing-md;
            }

            .playCard {
                @include drop-shadow;
                min-height: 100%;
                padding: $spacing-md;
                text-align: center;
                background: $white;
                border-radius: $rounding-sm;

                p, h3 {
                    color: $black
                }

                h3 {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                }

                img {
                    border-radius: $rounded;
                    max-width: 150px;
                    height: auto;
                    margin-bottom: $spacing-sm;
                }
            }
        }
    }

    .howToPlaySection {
        background-image: url("../images/yellowBg-v2.png");
    }

    .joinSection {
        display: flex;
        /*flex-direction: column;*/
        flex-direction: row;
        gap: $spacing-md;
        justify-content: center;
        background: $blue;
        /*@include image-background('../images/yellowBg-v2.png');*/
        /*  @media (min-width: $screen-md) {
            flex-direction: row;
        }*/

        .joinGame {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            font-size: 28px;

            p {
                color: white;
            }

            p, .button {
                margin-bottom: 0;
            }
        }
    }
    // Intro Section
    .introductionSection {
        background-color: $blue;

        .icon {
            scale: 0.5;

            @media (min-width: $screen-md) {
                scale: 1;
            }
        }

        p {
            font-size: 25px;

            @media (min-width: $screen-md) {
                font-size: 30px;
            }

            @media (min-width: $screen-lg) {
                font-size: 40px;
            }
        }
    }

    .mainContent {
        background-image: url(../images/yellowBg-v2.png);

        .bestSellersTitle {
            width: 70%;
            padding-bottom: 30px;
        }

        .video {
            position: relative;
            width: 53.3vw;
            height: 30vw;
            overflow: hidden;
            border-radius: 20px;
            margin-top: 2vh;
            box-shadow: 0 0 10px 0 black;

            .reactPlayer {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 20px;
            }
        }

        @media (max-width: $screen-md) {
            .video {
                width: 80vw;
                height: 45.1vw;
            }
        }

        .infoBoxes {
            @media only screen and (max-width: $screen-md) {
                //display: none;
            }

            padding: 30px;

            .calloutWrapper {

                &:nth-child(1) {
                    transform: rotateZ(-6deg) translateY(15px);
                }
                //first element
                &:nth-child(3) {
                    transform: rotateZ(6deg) translateY(15px);
                }


                .callout {
                    display: flex;
                    width: 100%;
                    padding-bottom: 30px;
                }
            }

            .infoBox {
                background-color: rgba(0,0,0,0.1);
                border-radius: $rounding-sm;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 16px;

                .title {
                    color: black;
                    font-size: 40px;
                    margin-bottom: unset;
                    font-weight: 600;
                    line-height: 1;
                }

                .description {
                    color: black;
                    font-size: 20px;
                    margin-top: auto;
                    font-weight: 600;
                }

                .img {
                    border-radius: 5px;
                    border: solid 3px white;
                    overflow: hidden; /* Ensures the rounded corners clip the background */
                    background-size: cover;

                    img {
                        display: block; /* Make sure the image is treated as a block element */
                        width: 100%; /* Ensure the image takes up the full width of its container */
                        height: auto; /* Maintain the aspect ratio of the image */
                        border-radius: 10px; /* Apply the same border-radius as the parent div */
                        opacity: 0;
                    }

                    &.desktopImg {
                        /*width: 75%;*/
                    }

                    &.mobileImg {
                        /*width: 25%;*/
                    }
                }
            }
        }

        h2 {
            text-align: center;
            text-transform: uppercase;
            color: $black;
            font-weight: 800;
        }
    }

    .reviewsSection {
        @include image-background('../images/template/reviewsBg.png');
        max-height: 500px;

        .reviewWidth {
            max-width: 350px;

            @media (min-width: $screen-sm) {
                max-width: 450px;
                max-height: 300px;
            }
        }

        .carousel {
            overflow: hidden;
            max-height: 300px;
        }

        .reviewCard {
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xs;
            border-radius: $rounding-sm;
            @include drop-shadow;
            min-height: 225px !important;
            overflow: hidden;

            .reviewTitle {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .reviewBody {
                max-height: 140px;
                display: flex;
            }

            .reviewText {
                font-size: 18px;
                font-weight: bold;
                color: $black;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                margin: auto;
            }
        }
    }
}


.modalPromotion {


    .modalPromotionBody {
        background-color: #E6017D;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .promotionRow {
            padding: 10px;
        }

        .promotionTitle {
            color: white;
            font-size: 35px;
            font-weight: bold;
            margin-bottom: unset !important;
        }

        .promotionText {
            color: white;
            font-size: 20px;
            font-weight: bold;
        }

        .promotionInput {
            box-sizing: border-box;
            border-radius: 2px;
            padding: 0px 0px 0px 16px;
            height: 50px;
            text-align: left;
            color: rgb(34, 34, 34);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(208, 51, 31);
        }

        .promotionButton {
            background: rgb(252, 235, 4);
            border-radius: 0px;
            border-style: none;
            border-color: rgb(0, 0, 0);
            border-width: 0px;
            color: rgb(34, 34, 34);
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1;
            white-space: normal;
            padding-top: 11px;
            padding-bottom: 11px;
            text-align: center;
            word-break: break-word;
            align-self: flex-end;
            cursor: pointer;
            height: auto;
            width: 100%;
            font-family: $font-secondary;

            &:hover {
                background-color: #DEF3FC !important;
                color: #E6017D !important;
            }

            .loading {
                display: inline-block;
                width: 24px;
                height: 24px;
                border: 5px solid rgba(255,255,255,.3);
                border-radius: 50%;
                border-top-color: #fff;
                animation: spin 1s ease-in-out infinite;
                -webkit-animation: spin 1s ease-in-out infinite;
            }

            @keyframes spin {
                to {
                    -webkit-transform: rotate(360deg);
                }
            }

            @-webkit-keyframes spin {
                to {
                    -webkit-transform: rotate(360deg);
                }
            }
        }

        .promotionFooterText {
            color: white !important;
            font-size: 12px;
            font-weight: 400;
            font-style: italic;
        }

        .promotionCancel {
            background: rgba(48, 59, 67, 0.01);
            border-radius: 2px;
            border-style: none;
            border-color: rgb(0, 0, 0);
            border-width: 0px;
            color: rgb(255, 255, 255);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1;
            white-space: normal;
            padding-top: 11px;
            padding-bottom: 11px;
            text-align: center;
            word-break: break-word;
            align-self: flex-end;
            cursor: pointer;
            height: auto;
            width: 100%;
        }

        .promotionDescription {
            color: white;
            font-size: 50px;
            font-weight: bold;
        }

        .wheel {

            @media (min-width: $screen-xs) {
                transform: scale(0.9) rotate(90deg);
            }

            @media (min-width: $screen-lg) {
                transform: scale(0.95) rotate(90deg);
            }
        }
    }
}

.promotionPopout {
    position: fixed;
    top: calc(50vh - 50px);
    left: -45px;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 0px 90px 90px 0px;
    z-index: 2147483647;
    background-color: rgb(230, 0, 125);
    cursor: pointer;
    display: table;
    animation: wobble 2s ease infinite;

    .popoutTato {
        max-width: 65px;
        margin-top: 15px;
        margin-left: 35px;
    }
}


@keyframes wobble {
    0% {
        transform: translateX(0%);
    }

    15% {
        transform: translateX(15px);
    }

    30% {
        transform: translateX(-7.5px);
    }

    40% {
        transform: translateX(2.5px);
    }

    50% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(0px);
    }
}
