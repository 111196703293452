@import '../../shared/variables.scss';

.backgroundWrap {
    background-image: url(../../images/yellowBg-v2.png);
    padding: $spacing-lg 0;
}

.policyImg {
    max-width: 75%;
    height: auto;
    max-height: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
}

.policyImgFloat{
    position: absolute;
    width:20vh;
}

@media screen and (max-width: $screen-lg) {
    .hideOnMobile{
        display:none;
    }
}

.policydoc {
    color: black;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 25px;
    padding: $spacing-sm;

    h1, h2, h3, h4, h5, h6 {
        color: black;
        margin-bottom: $spacing-xs;
    }

    text-align: left;
}

.policydoc h1, .policydoc h2, .policydoc h3, .policydoc h4 {
    font-weight: bold;
    text-transform: capitalize;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: 700;
}

.policydoc h1, .policydoc h2 {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}

.policydoc h1 {
    padding-top: 0.1em;
}

.policydoc table, .policydoc th, .policydoc td {
    border: 1px solid black;
}

.policydoc th, .policydoc td {
    padding: 0.5em;
}

.policydoc table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

$breakpoint-phone: 640px;
@media(max-width: $breakpoint-phone){
    .policydoc th, .policydoc td {
        font-size: 10px;
    }
    .policydoc tr {
        font-size: 12px;
    }
}

.policydoc div a {
    color: black;
    text-decoration: underline;
}

.policydoc div a:hover {
    color: #0366d6;
}

.policydoc .terms ol li, .policydoc .terms ul li {
    padding-bottom: 0.8em;
}

a {
    color: black;
}
