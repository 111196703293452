@import '../../shared/variables.scss';

@include button;

.toastsOverlay {
    position: fixed;
    background-color: transparent;
    top: 100px;
    right: 0;
    padding-top: $spacing-xs;
    padding-right: $spacing-xs;
    z-index: 10000;

    .buttonWrap {
        display: flex;
        justify-content: space-between;
    }

    .toastTitle {
        font-family: $font-secondary;
        padding: 4px 8px;
    }
}