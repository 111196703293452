@import '../../shared/variables.scss';

.footerContainer {
    position: relative;
    background-color: #65BECD;
    justify-content: center;
    padding: $spacing-sm;
}

.linksRow {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    gap: $spacing-xs;
    flex-direction: column;
    text-align: center;
    padding-bottom: 1rem;

    @media (min-width: $screen-md) {
        flex-direction: row;
        justify-content: center;
    }


    .linkText {
        padding-top: 3px;
        list-style: none;


        a {
            position: relative;
            display: inline-block;
            color: white;
            font-family: Bebas Neue;
            text-decoration: none;
            font-size: 22px;
        }

        a::after {
            position: absolute;
            content: '';
            display: block;
            width: 0;
            height: 1px;
            background: #ffffff;
            transition: width $speed-fast;
        }

        a:hover::after {
            width: 100%;
        }
    }
}

.copyrightText {
    color: white;
    /*font-family: Asap;*/
    font-size: 15px;
}

.socialsBar {
    //top: $menu-height;
    right: 0;
    padding: 8px;
    width: 230px;
    margin: auto;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: $font-primary;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    background-color: $blue;
    gap: $spacing-sm;
    padding: 10px;
    z-index: 5;
    border-radius: 0 0 0 30px;

    @media (min-width: $screen-lg) {
        padding-right: 37px;
        width: 270px;
    }

    svg {
        height: 25px;
        transition: scale $speed-fast;
        cursor: pointer;

        &:hover {
            scale: 1.2;
        }
    }
}
