@import '../../shared/variables.scss';

.gameCardBackground, .gameCardWrapper, .gameCardOverlay, .gameCardBackground {
    border-radius: $rounding-sm;
}

.gameCardBackground, .gameCardLogo {
    width: 370px;
    height: 460px;


    @media (min-width: $screen-lg) {
        width: 380px;
        height: 440px;
    }

    @media (min-width: $screen-lg) {
        width: 400px;
        height: 500px;
    }
}


.gameCardWrapper {
    min-width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: $spacing-md;

    &.hidden{
        opacity: 0.5;
    }

    .gameCardBackground {
        @include image-background;
        @include disable-select;

        &:hover {
            cursor: pointer;
            text-decoration: none !important;
            color: $white;
        }

        @include drop-shadow;
        position: relative;
        transition: scale $speed-fast;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .priceBanner {
            position: absolute;
            right: -0.1px;
            top: -0.1px;
            // border-radius: ;
            font-size: 20px;
            background-color: $hot-salmon;
            -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
            clip-path: polygon(100% 0, 0 0, 100% 100%);
            padding: 15px 15px 40px 40px;
            border-top-right-radius: $rounding-sm;
            width: 110px;
            height: 90px;
            background: #10232A !important;


            span {
                display: inline-block;
                transform: rotate(40deg);
                color: $white;
                text-decoration: line-through;
            }
        }

        .title {
            font-size: 30px;
            color: white;
            margin-bottom: 0;
            margin-top: $spacing-md
        }

        .label {
            position: absolute;
            left: -13%;
            top: 3%;
            font-size: 20px;
            width: 50%;
            height: fit-content;
            background: #10232A !important;
            transform: rotate(-40deg);
            padding: 5px;


        span {
            display: inline-block;
            color: $white;
            font-size: 25px;
        }
    }


    .gameCardOverlay {
        display: none;
        flex-direction: column;
        position: relative;
        background-color: rgba($black, 0.8);
        opacity: 1;
        padding: $spacing-sm;
        z-index: 1000;
        align-items: center;
        justify-content: center;

        .gamesIncludedHeader {
            margin-top: $spacing-sm;
            margin-bottom: $spacing-xs;
        }

        .includedGamesWrapper {
            padding: $spacing-sm;
            border-radius: $rounding-sm;
            border: 3px solid $white;
            background-color: rgba($black, 0.6);
            position: relative;

            p {
                font-family: $font-secondary;
                font-size: 20px;
                margin-bottom: $spacing-sm;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        p {
            font-size: 20px;
            margin-bottom: 0;
        }

        ul {
            padding: 0;
            margin: 0;
            display: flex;
            list-style: none;
            justify-content: space-between;
            min-width: 100%;

            li {
                color: $white;
                font-size: 20px;
                font-family: $font-secondary;
            }

            .icon {
                scale: 0.6;
            }

            span {
                font-family: $font-secondary;
            }
        }
    }

    &:hover {
        scale: 1.1;
        /*z-index: 1000;*/
        /* img {
                filter: brightness(0.2);
            }
*/
        .gameCardOverlay {
            display: flex;
        }
    }
}

    .gameCardLogo {
        /*@include abs-center;*/
        width: 70%;
        height: auto;
        margin-bottom: auto;
        margin-top: auto;
    }

    .gameCardOverlay {
        opacity: 0;
        min-height: 100%;
    }

    .clickOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .cardFooter {
        display: flex;
        flex-direction: column;
    }

    .addToCart {
        display: block;
        bottom: 0;
        z-index: 2;
        height: fit-content;
        width: 100%;
        background: $white;
        text-align: center;
        border-bottom-right-radius: $rounding-sm;
        border-bottom-left-radius: $rounding-sm;
        border: none;
        background: #EC0080;

        h4 {
            color: white;
            margin: unset;
        }
    }

    .gamesList {
        display: flex;
        margin: 10px;
        flex-wrap: wrap;
        margin-top: auto;
        justify-content: center;
        width: 80%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        .tinyCard {
            width: 30%;
            margin: 1.5%;
            border-radius: $rounding-xs;
            @include drop-shadow;
        }
    }
}