@import "../../shared/variables.scss";


.sideBar {

    @include drop-shadow;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 1s, opacity 1s;
    min-height: 100vh;
    transform: translateX(0%);
    background-color: #FFE52E;
    background: url(../../images/yellowBg-v2.png);
    z-index: 25;

    &.collapse {
        transform: translateX(-100%);
    }

    .sideBarInner {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        gap: 2.5vh;
        padding: 2vh;
        overflow: auto;
        height: 100%;
        /*justify-content: center;*/
        align-items: center;
        padding-top: 12vh;
        overflow-x: hidden;

        .welcomePic {
            max-width: 100%;
            margin-bottom: $spacing-xs;
        }

        .lobbyInfo {
            display: flex;

            p {
                font-size: 2.8vh;
                font-weight: 600;
                color: $off-black;

                strong {
                    font-size: 3vh;
                }
            }

            .qrCodeBox {
                width: fit-content !important;
                height: fit-content !important;
                padding: 1vh;
                background-color: $white;
                border-radius: $rounding-sm;
                display: flex;
                justify-content: center;
                align-items: center;

                .qrCode {
                }
            }
        }

        .playersJoined {
            display: flex;
            flex-direction: column;
            width: 98%;

            h2 {
                margin-bottom: 0;
                color: $black;
                text-align: center;
                font-size: 5vh;
            }

            .playersJoinedInner {
                /*padding: 1vh;*/
                background-color: $player-list-bg;
                border-radius: $rounding-sm;

                p {
                    font-weight: 700;
                    text-align: center;
                    font-size: 1.75vh;
                    margin: 1vh;
                }
            }
        }

        .buttonGroup {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: $spacing-sm;
        }
    }

    .collapseButton {
        width: 50px;
        height: 50px;
        background-color: #65BECD;
        border-radius: 50px;
        position: absolute;
        margin: auto;
        top: $spacing-xs;
        left: 94%;
        transition: 1s;
        display: flex;
        cursor: pointer;
        @include drop-shadow;



        .popoutText {
            margin: auto;
            transition: 1s;
            width: 0%;
            overflow: hidden;

            h4 {
                color: white;
                font-size: 30px;
                margin: unset;
            }
        }

        &.popout {
            width: 150px;

            .popoutText {
                width: 50%;
            }
        }

        .arrow {
            color: white;
            position: absolute;
            transform: rotate(-270deg);
            transition: 1s;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
            font-size: 50px;
            z-index: 1;
            background: #65BECD;
            border-radius: 50px;

            &.popout {
                transform: rotate(-90deg);
            }
        }
    }
}

.button {
    @include disable-select;
    margin-bottom: 1vh;
    display: inline-block;
    padding: (1vh / 2) 2vh;
    font-size: 3vh;
    font-family: $font-secondary;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: $white;
    background-color: #65BECD;
    border: none;
    border-radius: 1vh;
    box-shadow: 0 1vh #007DA5;
    transition: transform $speed-fast, box-shadow $speed-fast;

    &:hover {
        box-shadow: 0 0.6vh #007DA5;
        transform: translateY(4px);
    }

    &:active {
        box-shadow: 0 0.1vh #007DA5;
        transform: translateY(8px);
    }

    &.alternate {
        background-color: $hot-salmon;
        box-shadow: 0 1vh #B3434B;

        &:hover {
            box-shadow: 0 0.6vh #B3434B;
            transform: translateY(4px);
        }

        &:active {
            box-shadow: 0 0.1vh #B3434B;
            transform: translateY(8px);
        }
    }

    &.wide {
        min-width: 100%;
    }

    &.medium {
        font-size: 2.5vh;
        padding: (1vh / 2) 1.5vh;
    }

    &.small {
        font-size: 2vh;
        padding: (1vh / 2) 1vh;
    }

    &:disabled {
        opacity: 0.5;

        &:hover {
            box-shadow: 0 1vh #007DA5;
            transform: none;
        }
    }
}