@import "../../shared/variables.scss";

@include button;


.connectionIssueContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    z-index: 10000;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .connectionText {
        width: fit-content;
        height: fit-content;
        font-size: 30px;
        color: white;
        font-family: $font-primary;
        text-align: center;
    }

    .refreshButton {
        font-family: $font-secondary;
        padding: 10px;
        line-height: 1;
        font-weight: bolder;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
        width: fit-content;
        height: fit-content;
        font-size: 75px;
        color: white;
        border-radius: 20px;
        cursor: pointer;
        background-color: #FF5F6B;
        box-shadow: 0px 10px 0px 0px #B3434B;
        text-shadow: 2px 2px 2px #000000;
    }
}


.pageWrap {
    background: url(../../images/yellowBg-v2.png);
    padding: $spacing-xs 0;
    padding-top: 50px;

    h2, .nickName {
        font-size: 40px;
    }

    h2 {
        color: $black;
        margin-bottom: $spacing-sm;
    }

    .nickName {
        font-family: $font-secondary;
    }

    h2 {
        margin-bottom: unset;
    }

    p {
        font-size: 25px;
    }

    .avatarList {
        background-color: $player-list-bg;
        border-radius: $rounding-sm;
        padding: $spacing-sm;
        width: 95%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        overflow-y: scroll;
        margin-bottom: $spacing-xs;
        max-height: 275px;
        --scrollbar-border-thickness: 0px;
        --show-double-buttons: none;
        --scrollbar-button-color: #3F3F4600;
        --scrollbar-track-color: #E90F7900;
        --scrollbar-thumb-color: #E90F79;
        --scrollbar-border-radius: 20px;
        --scrollbar-width: 10px;
        --scrollbar-height: 10px;


        &::-webkit-scrollbar {
            width: var(--scrollbar-width, 20px);
            height: var(--scrollbar-height, 20px);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--scrollbar-thumb-color, #3B82F6);
            border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255));
            border-radius: var(--scrollbar-border-radius, 4px);
        }

        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-color, #A1A1AA);
        }

        &::-webkit-scrollbar-corner {
            background: var(--scrollbar-corner-color, #FFFFFF);
            border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255));
            border-radius: var(--scrollbar-border-radius, 4px);
        }

        &::-webkit-scrollbar-button:vertical:start:increment, #preview::-webkit-scrollbar-button:vertical:end:decrement, #preview::-webkit-scrollbar-button:horizontal:start:increment, #preview::-webkit-scrollbar-button:horizontal:end:decrement {
            display: var(--show-double-buttons, none);
        }

        .col {
            padding: 1%;
        }

        .avatar {
            margin: auto;
            border-radius: 1vh;
            margin: 1%;
            display: flex;

            &.selected {
                background-color: rgba(233, 15, 121, 0.5);
            }

            &.applied {
                background-color: #E90F79;
            }


            .potato {
                width: 100%;
                height: auto;
                margin: auto;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                &.fade {
                    opacity: 0.25;
                }
            }
        }
    }


    .topSection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-md;
        margin-bottom: $spacing-sm;
        margin-top: $spacing-sm;

        .playerControl {
            display: flex;
            flex-direction: column;

            .small {
                width: fit-content;
                margin: auto;
            }
        }

        .pickedTato {
            width: 120px;
            height: 120px;
            background-color: $player-list-bg;
            border-radius: $rounded;
            position: relative;

            .tato {
                position: absolute;
                top: -$spacing-md;
            }

            p {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                font-family: $font-secondary;
                font-size: 30px;
                margin-bottom: 0;

                &.small {
                    font-size: 25px;
                    bottom: 15px;
                }

                &.smaller {
                    font-size: 20px;
                    bottom: 20px;
                }
            }
        }

        .button {
            @media (min-width: $screen-md) {
                padding-left: $spacing-lg;
                padding-right: $spacing-lg;
            }
        }
    }

    .voteForGames {
        background-color: $player-list-bg;
        padding: $spacing-sm;
        border-radius: $rounding-sm;
        margin-bottom: $spacing-sm;
        justify-content: center;
        align-items: center;

        .noGamesText {
            color: $off-black;
            margin: 20px;
        }

        @media (max-width: $screen-md) {
        }
    }

    .buyMoreList {
        position:relative;
        background-color: $player-list-bg;
        padding: $spacing-sm;
        border-radius: $rounding-sm;
        margin-bottom: $spacing-sm;
        justify-content: center;
        align-items: center;
        width:min-content;
        left:50%;
        transform:translateX(-50%);

        @media (max-width: $screen-md) {
        }
    }
}
