

.slick-list {
    overflow: visible;
}

.slick-dots {
    bottom: -3rem;
    
}

.slick-thumb li {
    width: 2vh;
    height: 2vh;
    
    
}

.slick-slide {
    opacity: 0.75;
    transform: scale(0.75);
    transition: all 0.5s;
}

.slick-slide.slick-active {
    opacity: 1;
    transform: scale(1);
}

.slick-thumb li .carousel-dot {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /*background-color: white;*/
    border: 0.2rem solid white;
}

.slick-active .carousel-dot {
    background-color: white;
}

.slick-prev {
    left: 0%;
}

.slick-arrow {
    width: 6rem;
    height: 6rem;
    z-index:1;
}

.slick-next {
    right: 0%;
}


