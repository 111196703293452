/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.howToPlayModal {
    width: 80%;
    max-width: unset;
    margin: auto;
}
/*
#canvas {
    width: 100%;
}*/

.loadingScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FF5F6B;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: white;
    color: white;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    transform: scale(1);
}

    .dot-pulse::before, .dot-pulse::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: white;
        color: white;
    }

    .dot-pulse::before {
        box-shadow: 9964px 0 0 -5px;
        animation: dot-pulse-before 1.5s infinite linear;
        animation-delay: 0s;
    }

    .dot-pulse::after {
        box-shadow: 10034px 0 0 -5px;
        animation: dot-pulse-after 1.5s infinite linear;
        animation-delay: 0.5s;
    }

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9964px 0 0 -5px;
    }

    30% {
        box-shadow: 9964px 0 0 2px;
    }

    60%, 100% {
        box-shadow: 9964px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10034px 0 0 -5px;
    }

    30% {
        box-shadow: 10034px 0 0 2px;
    }

    60%, 100% {
        box-shadow: 10034px 0 0 -5px;
    }
}
