@import "../shared/variables.scss";
@import "../shared/core.scss";

.flexCenterXY {
    align-items: center;
}

.band {
    padding: $spacing-md 0;

    @media (min-width: $screen-lg) {
        padding: $spacing-lg 0;
    }
}

.filterSection {
    @include image-background('../images/woodBg.png');
    padding: $spacing-xs 0;

    .filterBar {
        display: flex;
        justify-content: center;
        gap: $spacing-md;
        margin-bottom: 0;

        li {
            padding: $spacing-sm $spacing-xs;
            @include disable-select;
            cursor: pointer;
            font-family: $font-secondary;
            font-size: 30px;
            color: $white;

            &.active {
                background: $off-white;
                border-radius: $rounding-md;
                color: $off-black;
                @include drop-shadow;
            }
        }
    }

    @media (max-width: 500px) {
        .filterBar {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $spacing-xs;
        }
    }
}


.pageShop {

    p {
        font-family: $font-primary;
        color: $white;
        line-height: 1.2;
    }
    // Hero section
    .heroBackground {
        @include image-background('../images/template/heroWelcome.png');
        min-width: 100%;
        position: relative;

        &::after {
            position: absolute;
            background-color: rgba($black, 0.75);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
        }

        h1 {
            position: relative;
            z-index: 10;
            margin-bottom: 0;
        }
    }

    .heroHeading {
        font-size: 40px;
    }

    .gameListingSection {
        background-image: url(../images/yellowBg-v2.png);

        h2 {
            text-align: center;
            text-transform: uppercase;
            color: $black;
            font-weight: 800;
        }
    }
}
