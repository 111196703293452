@import '../../shared/variables.scss';



.startingGameWrapper {
    @include disable-select();
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    z-index: 2000000;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    &.show {
        display: flex !important;
    }

    &.herdMentality {
        @include image-background('../images/storeBackgrounds/herdMentality.png');
    }

    &.canOfSquirms {
        @include image-background('../images/storeBackgrounds/squirms.png');
    }

    &.scrawl {
        @include image-background('../images/storeBackgrounds/scrawl.png');
    }

    &.theChameleon {
        @include image-background('../images/storeBackgrounds/theChameleon.png');
    }

    &.drawAlongWithDave {
        @include image-background('../images/storeBackgrounds/drawWith.png');
    }

    &.colourBrain {
        @include image-background('../images/storeBackgrounds/colourBrain.png');
    }

    &.pubQuiz {
        @include image-background('../images/storeBackgrounds/pubQuiz.jpg');
    }

    &.anagramMagic {
        background: linear-gradient(30deg, rgba(24,221,254,1) 0%, rgba(65,184,254,1) 100%);
    }

    &.mathsMaster {
        background: linear-gradient(30deg, rgba(119,113,248,1) 0%, rgba(83,255,186,1) 100%);
    }

    &.wordWheel {
        background: linear-gradient(30deg, rgba(161,140,209,1) 0%, rgba(251,194,235,1) 100%);
    }

    &.catchphrase {
        background: #1e236e;
    }
        
    &.cantSayUmm {
        @include image-background('../images/storeBackgrounds/ycsu-storeBG-2.png');
    }

    .startingGameInner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $spacing-md;

        h1 {
            text-align: center;
        }

        img {
            max-width: 200px;
            height: auto;
            -webkit-animation: rotate-center $speed-slow ease-in-out infinite both;
            animation: rotate-center $speed-slow ease-in-out infinite both;
        }
    }
}



/* ----------------------------------------------
 * Generated by Animista on 2023-8-2 15:35:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
