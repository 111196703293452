@import '../../shared/variables.scss';
@include button;

.screenOverlay {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 26;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .screenOverlayBG {
        position: absolute;
        background-color: black;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: opacity ease-in-out 0.5s;
    }

    .modalContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .modal {
            position: relative;
            width: 550px;
            padding: min(2vw, 2vh);
            background-color: #ffe52e;
            border-radius: 40px;
            box-shadow: 0 0 min(2vw, 2vh) min(-0.2vw, -0.2vh) black;
            height: fit-content;
            transform: scale(0);
            transition: 0.5s cubic-bezier(.48,-0.45,.86,.49) transform;
            //border: solid 8px $blue;


            .closeButton {
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
                margin: 20px;
                transform: scale(1);
                transition: transform linear 0.2s;

                img {
                    width: 100%;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }

            .signUp {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;
                padding: 0 10px;

                .email {
                    //width: 50%;
                    height: 40px;
                    margin-top: 4px;
                }

                .buttonWrapper {
                    position: relative;
                    width: fit-content;
                    margin: auto;

                    &:hover {
                        img {
                            animation: swing 1s infinite;
                        }

                        @keyframes swing {
                            0%, 100% {
                                transform: rotate(-3deg);
                            }

                            20% {
                                transform: scale(.95);
                            }

                            50% {
                                transform: rotate(3deg);
                            }

                            80% {
                                transform: scale(.95);
                            }
                        }
                    }

                    .button {
                        width: fit-content;
                        height: 40px;
                        white-space: nowrap;
                        border-radius: 10px;
                        background-color: $hot-salmon;
                        color: white;
                        font-family: 'Bebas Neue';
                        font-size: 18px;
                        padding-top: 8px;
                        padding-bottom: 4px;
                    }

                    img {
                        position: absolute;
                        bottom: 0px;
                        right: -8px;
                        width: 45px;
                        pointer-events: none;
                        transition: transform 0.5s linear;
                    }
                }
            }

            .dontShow {
                display: block;
                margin: auto;
                width: fit-content;

                .link {
                    cursor: pointer;
                    font-family: 'Bebas Neue';
                    font-size: 15px;
                }

                .button {
                    width: fit-content;
                    height: 35px;
                    white-space: nowrap;
                    border-radius: 10px;
                    color: white;
                    font-family: 'Bebas Neue';
                    font-size: 16px;
                    padding-top: 8px;
                    padding-bottom: 4px;
                    margin-bottom: 15px;
                }
            }

            h1 {
                color: black;
                font-size: 45px;
                text-align: center;
                padding: 20px;
                margin: 0 25px;
            }

            .columbs {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 0.9fr;

                .columb {
                    width: 100%;
                    padding: 10px;
                }
            }

            .description {
                text-align: center;
                font-size: 18px;
                font-family: 'Futura PT';
                font-weight: bold;
            }

            .tick {
                float: left;
                width: 20px;
                margin-right: 10px;
            }

            .callouts {
                font-family: Bebas Neue;
                font-size: 20px;
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 600px) {
            .modal {
                width: 90vw;
            }

            .columbs {
                grid-template-columns: 1fr !important;
            }

            .description {
                margin-bottom: 0 !important;
            }

            .callouts {
                margin-bottom: 8px !important;
            }

            .calloutWrapper {
                padding-right: 40px;
                width: fit-content !important;
                margin: auto !important;
            }

            .signUp {
                display: block !important;
            }

            .signUp {
                .button {
                    margin-top: 15px !important;
                }
            }

            .dontShow {
                .button {
                    margin-top: 5px !important;
                }
            }
        }
    }

    &.show {
        .screenOverlayBG {
            opacity: 0.5;
        }

        .modal {
            transform: scale(1);
            transition: 0.5s cubic-bezier(0,.84,.3,1.3) transform;
        }
    }
}
