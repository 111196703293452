@import "../shared/variables.scss";
@import "../shared/core.scss";

@include button;

.flexCenterXY {
    align-items: center;
}

.band {
    padding: $spacing-md 0;

    @media (min-width: $screen-lg) {
        padding: $spacing-md 0;
    }

    &.sm {
        padding: $spacing-sm 0;
    }

    &.noTop {
        padding-top: 0;
    }
}

.rounded {
    border-radius: $spacing-xs;
}

.pageShopSingle {
    @include image-background-fixed;

    .backButton {
        display: flex;
    }

    .gameLogo {
        margin-bottom: $spacing-sm;

        &.hero {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            min-width: 100vw;
            display: flex;
            justify-content: start;
            align-items: start;
            justify-content: center;
            align-items: center;
            left: 50%;
            /* top: 50%; */
            transform: translateX(-50%);
            padding: $spacing-xs;
            min-height: 200px;

            @media (min-width: $screen-sm) {
                min-height: 250px;
            }

            @media (min-width: $screen-md) {
                min-height: 300px;
            }

            @media (min-width: $screen-lg) {
                min-height: 450px;
            }
            /**/

            &::after {
                position: absolute;
                /*background-color: rgba($black, 0.5);*/
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
            }
        }


        img {
            max-width: 270px;
            height: auto;
            z-index: 1;

            @media (min-width: $screen-sm) {
                max-width: 350px;
            }

            @media (min-width: $screen-md) {
                max-width: 400px;
            }

            @media (min-width: $screen-lg) {
                max-width: 550px;
            }
        }

        .storeLogo {

            &.hasSecond {
                animation: cycle 5s ease-in-out infinite;

                @keyframes cycle {
                    0% {
                        opacity: 100%;
                    }

                    40% {
                        opacity: 100%;
                    }

                    50% {
                        opacity: 0;
                    }

                    90% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 100%;
                    }
                }
            }
        }

        .storeLogo2 {
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            max-height: 45vh;
            animation: cycle2 5s ease-in-out infinite;

            @keyframes cycle2 {
                0% {
                    opacity: 0;
                }

                40% {
                    opacity: 0;
                }

                50% {
                    opacity: 100%;
                }

                90% {
                    opacity: 100%;
                }

                100% {
                    opacity: 0;
                }
            }
        }
    }

    .starRow {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: center;
        margin-bottom: $spacing-xs;


        .star {
            width: 1em;
            height: 1em;
        }

        .starSum {
            /*cursor: pointer;*/
        }
    }


    .cardFooter {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: $spacing-xs;
        width: 100%;

        @media (min-width: $screen-sm) {
            width: 90%;
        }

        @media (min-width: $screen-md) {
            width: 80%;
        }

        @media (min-width: $screen-lg) {
            width: 70%;
        }

        .gamesList {
            display: flex;
            margin: 10px;
            flex-wrap: wrap;
            margin-top: auto;
            justify-content: center;

            .tinyCard {
                width: 30%;
                margin: 1.5%;
                border-radius: $rounding-sm;
                @include drop-shadow;
                transition: scale $speed-fast;

                &:hover {
                    scale: 1.1;
                    cursor: pointer;
                    z-index: 1000;

                    .gameCardOverlay {
                        display: flex;
                    }
                }
            }
        }
    }

    .gameInfoWrapper {
        background-color: $white;
        border-radius: $rounding-sm;
        padding: $spacing-xs;
        max-width: 400px;
        overflow: hidden;

        @media (max-width: $screen-lg) {
        }

        @media (max-width: $screen-xl) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $spacing-md;
            /*max-width: 800px;*/
        }

        @include drop-shadow;

        .giftHint {
            background-color: #0a8c44;
            color: $white;
            margin: -10px;
            padding: 10px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            p {
                font-size: 18px;
                font-weight: 600;
                margin: auto;
                text-align: center;
            }

            img {
                height: 36px;
            }
        }

        .button {
            padding: $spacing-xs;
            width: 200px;

            &:nth-of-type(2) {
                background-color: $hot-salmon;
                box-shadow: 0 9px #B3434B;

                &:hover {
                    box-shadow: 0 5px #B3434B;
                    transform: translateY(4px);
                }

                &:active {
                    box-shadow: 0 1px #B3434B;
                    transform: translateY(8px);
                }
            }
        }

        .gamePrice {
            font-family: $font-secondary;
            font-size: 35px;
            margin-bottom: $spacing-xs;
            margin-top: $spacing-sm;
        }

        .gameInfo, .gameDescription {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .gameLicence{
            font-size: 1.1vh;
        }

        .gameInfo {
            display: flex;
            justify-content: space-between;
            min-width: 100%;

            li {
                color: $white;
                font-size: 20px;
                font-family: $font-secondary;
                width: 33%;
            }

            .icon {
                scale: 0.8;

                img {
                    width: 100%;
                }
            }

            span {
                font-family: $font-secondary;
                color: $black;
            }
        }

        .gameDescription {
            text-align: left;
            /* padding-bottom: $spacing-sm;*/

            .descriptionHeader, .descriptionBody {
                font-size: 20px;
            }

            .descriptionHeader {
                font-size: 21px;
                font-family: $font-secondary;
                margin-bottom: 0;
            }

            .descriptionBody {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: $spacing-sm;
            }
        }

        .addons {
            .header {
                font-size: 21px;
                font-family: $font-secondary;
                margin-bottom: 0;
            }

            .addonsList {
                display: flex;
                margin: 5px;
                flex-wrap: wrap;
                justify-content: center;
                width: calc(100% - 10px);
                align-items: center;

                .addonItem {
                    display: flex;
                    width: 100%;
                    background: $hot-salmon;
                    border-radius: 10px;
                    padding: 2px;
                    transition: scale $speed-fast;
                    cursor: pointer;

                    &:hover {
                        scale: 1.05;
                    }

                    p {
                        color: $off-white;
                        font-family: $font-secondary;
                        margin: 0;
                        font-size: min(5vw, 22px);
                    }

                    .details {
                        margin: 10px;
                        display: flex;
                        align-items: center;

                        .detailsWrapper {

                            .addonHeader {
                                text-align: left;
                            }

                            .addonPrice {
                                text-align: left;
                            }
                        }
                    }

                    .tinyCard {
                        width: 40%;
                        max-width: 110px;
                        margin: 1.5%;
                        border-radius: $rounding-xs;
                        @include drop-shadow;
                    }
                }
            }
        }
    }

    .reviewsSection {
        @include image-background('../images/template/reviewsBg.png');
        max-height: 500px;

        .reviewWidth {
            max-width: 350px;

            @media (min-width: $screen-sm) {
                max-width: 450px;
                max-height: 300px;
            }
        }

        .carousel {
            overflow: hidden;
            max-height: 300px;
        }

        .reviewCard {
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xs;
            border-radius: $rounding-sm;
            @include drop-shadow;
            min-height: 225px !important;
            overflow: hidden;

            .reviewTitle {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .reviewBody {
                max-height: 140px;
                display: flex;
            }

            .reviewText {
                font-size: 18px;
                font-weight: bold;
                color: $black;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                margin: auto;
            }
        }
    }

    .howItWorksBox {
        width: fit-content;
        min-height: 100px;
        border: solid white 3px;
        border-radius: $spacing-sm;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: auto;

        .howItWorksBlock {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 800px;

            .number {
                margin-bottom: $spacing-xs;
                background-color: white;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                display: flex;


                p {
                    font-size: 40px;
                    font-family: $font-secondary;
                    color: #212B3D;
                    font-weight: 600;
                    margin: auto;
                }
            }

            .title {
                margin-bottom: 0;
            }

            .description {
                font-size: 20px;
                font-family: $font-primary;
                color: white;
                margin: auto;
                text-align: center;
            }
        }
    }

    .previewImages {
        @include drop-shadow;
        // border: 5px solid $black;
        border-radius: $rounding-sm;
        width: fit-content;
        /*min-width: 350px;*/

        @media (max-width: 1449px) {
            /*max-width: 576px;*/
            margin: 0 auto;
        }

        @media (max-width: $screen-xl) {
            /*max-width: 510px;*/
        }

        .imageRow {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            &.reverse {
                flex-direction: row-reverse;
            }

            img {


                &.mobilePreview {
                    max-width: 93.75px;

                    @media (min-width: $screen-lg) {
                        max-width: 131.25px;
                        height: 240px;
                    }

                    @media (min-width: $screen-xl) {
                        max-width: 150px;
                        height: 250px;
                    }

                    @media (min-width: calc($screen-xl + 250px)) {
                        max-width: 187.5px;
                        height: 275px;
                    }
                }

                &.desktopPreview {
                    max-width: 250px;

                    @media (min-width: $screen-lg) {
                        max-width: 350px;
                        height: 240px;
                    }

                    @media (min-width: $screen-xl) {
                        max-width: 450px;
                        height: 250px;
                    }

                    @media (min-width: calc($screen-xl + 250px)) {
                        max-width: 500px;
                        height: 275px;
                    }
                }
            }
        }

        &.mobile {

            @media (max-width: $screen-xl) {
                margin: auto;
                max-width: 800px;

                .imageRow {

                    img {
                        min-width: 100%;
                        max-width: 100%;
                        height: auto;
                    }

                    &.singleDesktopRow {
                        div {
                            flex: 1;
                        }
                    }

                    &.doubleColRow {
                        div {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    .gamePreviews {
    }
}
