@import '../../../shared/variables.scss';
@include button;

.screenOverlay {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 26;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .screenOverlayBG {
        position: absolute;
        //background-color: black;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: opacity ease-in-out 0.5s;
    }

    .modalContainer {
        position: absolute;
        $margin: min(4vw, 4vh);
        width: calc(100% - 2 * $margin);
        height: calc(100% - 2 * $margin);
        margin: $margin;

        .modal {
            position: relative;
            width: 100%;
            background-color: $off-white;
            border-radius: 40px;
            box-shadow: 0 0 min(2vw, 2vh) min(-0.2vw, -0.2vh) black;
            height: 100%;
            transform: scale(0);
            transition: 0.5s cubic-bezier(.48,-0.45,.86,.49) transform;
            overflow: hidden;


            .detailsContainer {
                position: relative;
                width: 45%;
                background-color: $hot-salmon;
                height: 100%;
                text-align: center;
                padding: 4% 5% 4% 5%;
                border-radius: 40px;

                .wormyBG {
                    position: absolute;
                    width: 110%;
                    height: 110%;
                    left: -5%;
                    top: -5%;
                    opacity: 0.04;
                    object-fit: cover;
                    z-index: 0;
                }

                .detailsZWrapper {
                    position: relative;
                    z-index: 1;
                    height: 100%;
                    width: 100%;

                    .titleImage {
                        height:20%;
                        margin-bottom: min(4vw, 4vh);
                    }

                    .heading {
                        position: relative;
                        font-family: "Bebas Neue";
                        font-size: min(4.5vw, 4.5vh);
                        paint-order: stroke fill;
                        margin-bottom: min(4vw, 4vh);
                        color: $white;
                    }

                    .midSection {
                        display: flex;
                        height: 40%;

                        .QRContainer {
                            display: flex;
                            background-color: $blue;
                            padding: min(2vw,2vh);
                            border-radius: min(2vw,2vh);
                            box-shadow: 0 10px 0 0 $blue-accent;
                            height: min-content;

                            .QR {
                            }
                        }

                        .callouts {
                            margin-left: 20px;

                            .center {
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);

                                h4 {
                                    position: relative;
                                    color: $white;
                                    font-family: 'Futura PT';
                                    text-align: left;
                                    font-weight: bold;

                                    b {
                                        color: #FFEF31;
                                    }
                                }
                            }
                        }
                    }
                }

                .gamesCarouselContainer {
                    position: relative;
                    bottom: 6vh;

                    .fade {
                        position: absolute;
                        left: -5px;
                        top: -5px;
                        width: calc(100% + 10px);
                        height: calc(100% + 10px);
                        background: linear-gradient(90deg, $hot-salmon 0%, $hot-salmon 5%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 85%, $hot-salmon 95%);
                        z-index: 1;
                    }

                    .corouselMask {
                        overflow: hidden;

                        .gamesCarousel {
                            display: flex;
                            width: max-content;
                            gap: 10px;
                            padding-right: 10px;
                            animation: bannermove 20s linear infinite;

                            .game {
                                overflow: hidden;
                                border-radius: 10px;
                                width: fit-content;

                                img {
                                    height: 7vh;
                                    padding: 0;
                                }
                            }

                            @keyframes bannermove {
                                0% {
                                    transform: translateX(0);
                                }

                                100% {
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }
                }
            }

            .heroContainer {
                position: absolute;
                width: 60%;
                height: 100%;
                right: -2.5%;
                top: 0;
                z-index: -1;

                .fade {
                    position: absolute;
                    left: -1%;
                    top: -1%;
                    width: 102%;
                    height: 102%;
                    background-color: black;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &.show {
        .screenOverlayBG {
            opacity: 0.5;
        }

        .modal {
            transform: scale(1);
            transition: 0.5s cubic-bezier(0,.84,.3,1.3) transform;
        }
    }
}
