@import '../../shared/variables.scss';

.gameCardBackground, .gameCardWrapper, .gameCardOverlay, .gameCardBackground, .gameCard {
    border-radius: $rounding-sm;
}

.gameCardBackground, .gameCardLogo, .cardDescriptionBox {
    width: 100%;
    height: 195px;


    @media (min-width: $screen-lg) {
        height: 212px;
    }
}

.gameCard {
    width: 309px;

    @media (min-width: $screen-lg) {
        width: 335px;
    }
}


.gameCardWrapper {
    min-width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: $spacing-md;

    &.hidden {
        opacity: 0.5;
    }

    .gameCard {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        height: fit-content;
        @include drop-shadow;
        transition: scale $speed-fast;

        &:hover {
            scale: 1.05;
            /*z-index: 1000;*/
            /* img {
                filter: brightness(0.2);
            }*/
            /*.gameCardOverlay {
                display: flex;
            }*/
        }
    }

    .votesCounter {
        position: absolute;
        border-radius: 50%;
        top: -10px;
        left: -10px;
        z-index: 1;
        font-size: 30px;
        width: 40px;
        height: 40px;
        display: flex;

        span {
            display: inline-block;
            color: $white;
            margin: auto;
        }
    }

    .gameCardBackground {
        @include image-background;
        @include disable-select;
        overflow: hidden;

        &:hover {
            cursor: pointer;
            text-decoration: none !important;
            color: $white;
        }

        position: relative;
        border-bottom-left-radius: unset !important;
        border-bottom-right-radius: unset !important;
        margin: auto;

        &.rounded {
            border-radius: $rounding-sm !important;
        }

        .priceBanner {
            position: absolute;
            right: -0.1px;
            top: -0.1px;
            // border-radius: ;
            font-size: 20px;
            background-color: $hot-salmon;
            -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
            clip-path: polygon(100% 0, 0 0, 100% 100%);
            padding: 15px 15px 40px 40px;
            border-top-right-radius: $rounding-sm;
            width: 110px;
            height: 90px;
            background: #10232A !important;


            span {
                display: inline-block;
                transform: rotate(40deg);
                color: $white;
            }
        }

        .gameCardOverlay {
            display: none;
            flex-direction: column;
            position: relative;
            background-color: rgba($black, 0.8);
            opacity: 1;
            padding: $spacing-sm;
            z-index: 2000;
            align-items: center;
            justify-content: center;



            ul {
                padding: 0;
                margin: 0;
                display: flex;
                list-style: none;
                justify-content: space-between;
                min-width: 100%;

                li {
                    color: $white;
                    font-size: 20px;
                    font-family: $font-secondary;
                }

                .icon {
                    scale: 0.6;
                }

                span {
                    font-family: $font-secondary;
                }
            }
        }

        p {
            font-size: 20px;
            margin-bottom: 0;
            position: absolute;
            bottom: $spacing-sm;
            left: 0;
            right: 0;
            padding-left: $spacing-xs;
            padding-right: $spacing-xs;
        }

        .addToCart {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: fit-content;
            background: $black;
            color: $white;
            font-family: $font-primary;
            font-size: 25px;
            border: 0;
            border-radius: $rounding-sm 0 $rounding-md 0;
            line-height: 1;
            padding-right: $spacing-xs;
            padding-bottom: $spacing-xs;
            padding-top: 2px;
        }

        .banner {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: $black;
            color: $white;
            font-size: 20px;
            border: 0;
            line-height: 1;
            padding: 7.5px;
            font-family: $font-primary;
            font-weight: bold;
            overflow: hidden;
        }

        .addonBanner {
            display: block;
            position: absolute;
            top: 5%;
            left: -42%;
            transform: rotate(-45deg);
            width: 100%;
            background: #EC0080;
            color: $white;
            font-size: 20px;
            border: 0;
            line-height: 1;
            padding: 7.5px;
            font-family: $font-primary;
            font-weight: bold;
            overflow: hidden;
        }
    }

    .cardDescriptionBox {
        height: fit-content;
        background: $white;
        text-align: start;
        height: 108px;

        h4 {
            color: black;
            margin: unset;
        }

        p {
            font-size: 20px;
            color: black;
            margin: unset;
        }
    }

    .addToCartBox {
        height: fit-content;
        text-align: center;
        border-bottom-right-radius: $rounding-sm;
        border-bottom-left-radius: $rounding-sm;
        border: none;
        background: #EC0080;

        h4 {
            color: white;
            margin: unset;
        }

        &:disabled {
            background: grey;
        }
    }

    .gameCardLogo {
        @include abs-center;
        max-width: 90%;
        height: auto;
        /*  position: absolute;
        width: 90%;
        margin: auto;
        height: auto;
        bottom: $spacing-sm;
        top: 0;
        left: 0;
        right: 0;*/

        &.hasSecond {
            animation: cycle 5s ease-in-out infinite;

            @keyframes cycle {
                0% {
                    opacity: 100%;
                }

                40% {
                    opacity: 100%;
                }

                50% {
                    opacity: 0;
                }

                90% {
                    opacity: 0;
                }

                100% {
                    opacity: 100%;
                }
            }
        }
    }

    .gameCardLogo2 {
        @include abs-center;
        max-width: 90%;
        max-height: 70%;
        /*  position: absolute;
        width: 90%;
        margin: auto;
        height: auto;
        bottom: $spacing-sm;
        top: 0;
        left: 0;
        right: 0;*/
        animation: cycle2 5s ease-in-out infinite;

        @keyframes cycle2 {
            0% {
                opacity: 0;
            }

            40% {
                opacity: 0;
            }

            50% {
                opacity: 100%;
            }

            90% {
                opacity: 100%;
            }

            100% {
                opacity: 0;
            }
        }
    }

    .clickOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &.lobby {
        margin-bottom: unset !important;

        .gameCard {
            width: 35vh;
        }

        .gameCardBackground {
            height: 22.5vh;
        }

        .banner {
            font-size: 2.25vh;
            padding: 0.75vh;
        }
    }
}