@import '../../shared/variables.scss';

.header {
    height: $menu-height;
    background-color: $hot-salmon;

    &.herdMentality {
        background-color: $header-color-herd-mentality;
    }

    &.canOfSquirms {
        background-color: $header-color-can-of-squirms;
    }

    &.scrawl {
        background-color: $header-color-scrawl;
    }

    &.theChameleon {
        background-color: $header-color-the-chameleon;
    }

    &.drawAlongWithDave {
        background-color: $header-color-draw-along-with-dave;
    }

    &.colourBrain {
        background-color: $header-color-colour-brain;
    }

    &.pubQuiz {
        background-color: $header-color-pub-quiz;
    }
}

.headerInner {
    @include disable-select;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    img {
        cursor: pointer;
        max-width: 100px;
        height: auto;

        @media (min-width: $screen-md) {
            max-width: 150px;
        }
    }
}

.mainMenu {

    // align-self: baseline;

    img {
        max-width: 30px;
        height: auto;
        display: block;
    }

    @media (max-width: $screen-lg) {
        gap: 0 !important;
        position: absolute;
        background-color: $off-white;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        min-width: 90%;
        z-index: 1000;
        padding: 15px;
        z-index: 100000000;

        border-radius: $rounding-sm;
        border-top: 3px solid $hot-salmon;

        ul {
            padding: 0;
            flex-direction: column;

            li {
                p, span, a {
                    color: $off-black !important;
                }

                border-bottom: 1px solid rgba(255, 255, 255, 0.8);
                padding: 10px;

                &:last-of-type {
                    border-bottom: none;
                }

                span {
                    display: block;
                    width: 100%;
                }

                border-bottom: 1px solid rgba($black, 0.2);
            }
        }
    }

    @media (min-width: $screen-lg) {
        position: static;
        display: flex;

        ul {
            li {
                a {
                    min-height: 100%;
                    display: block;
                    padding: $spacing-sm $spacing-xs;
                }
            }
        }

        img {
            display: none;
        }
    }
}

.hide {

    @media (max-width: $screen-lg) {
        display: none !important;    
    }    
}

.show {
    @media (max-width: $screen-lg) {
        display: block !important;    
    }
}

.menuItems {


    ul {
        display: flex;
        margin: 0;

        li {
            display: inline-block;

            a, span {
                text-decoration: none;
                color: white;
                font-family: $font-secondary;
                font-size: 20px;

                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 1px;
                    background: #ffffff;
                    transition: width $speed-fast
                }

                &:hover::after {
                    width: 100%;
                }

                &.selected::after {
                    width: 100%;
                }
            }
        }
    }


    @media (min-width: $screen-lg) {

        ul {
            gap: $spacing-md;

            li {
                a, span {
                    font-size: 28px;
                }
            }
        }
    }
}

.actions {
    ul {
        padding: 0;
        gap: 10px !important;

        @media (min-width: $screen-md) {
            gap: 20px !important;
        }

        li {
            position: relative;
            // cursor: pointer;

            .actionsItem {
                cursor: pointer;
                width: 40px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;

                // @media (min-width: $screen-md) {
                //     width: 40px;
                // }

                img {
                    max-width: 30px;
                    height: auto;
                }
            }

            .basketIcon {
                position: relative;
                
                .basketCount {
                    position: absolute;
                    background-color: $black;
                    border-radius: $rounded;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 16px;

                    top: -10px;
                    right: -15px;
                    font-weight: 700;
                }
            }


            ul {
                gap: 0 !important;
                flex-direction: column;
                position: absolute;
                top: 90px;
                left: -75px;
                width: 200px;
                padding: 15px;
                background-color: $off-white;
                z-index: 1000;

                border-top: 3px solid $hot-salmon;
                border-radius: $rounding-sm;

                li {
                    padding: $spacing-xs;
                    border-bottom: 1px solid rgba($black, 0.2);

                    p, span, a {
                        color: $off-black !important;
                    }

                    &:last-of-type {
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }
}

.toggleMenu {
    img {
        margin-top: 5px;
        display: block;

        @media (min-width: $screen-lg) {
            display: none;
        }
    }
}

.socialsBar {
    position: absolute;
    //top: $menu-height;
    right: 0;
    width: 225px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: $font-primary;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    background-color: $blue;
    gap: $spacing-sm;
    padding: 6px;
    z-index: 5;
    border-radius: 0 0 0 30px;

    @media (min-width: $screen-lg) {
        padding-right: 37px;
        width: 270px;
    }
    /*    &.herdMentality {
        background-color: $header-color-herd-mentality;
    }

    &.canOfSquirms {
        background-color: $header-color-can-of-squirms;
    }

    &.scrawl {
        background-color: $header-color-scrawl;
    }

    &.theChameleon {
        background-color: $header-color-the-chameleon;
    }

    &.drawAlongWithDave {
        background-color: $header-color-draw-along-with-dave;
    }

    &.colourBrain {
        background-color: $header-color-colour-brain;
    }

    &.pubQuiz {
        background-color: $header-color-pub-quiz;
    }*/

    svg {
        height: 25px;
        transition: scale $speed-fast;
        cursor: pointer;

        &:hover {
            scale: 1.2;
        }
    }
}