

@font-face {
    font-family: 'Bebas Neue';
    src: url("../assets/fonts/BebasNeue-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Futura PT';
    src: url("../assets/fonts/futura-pt-book.ttf") format('truetype');
    }

@font-face {
    font-family: 'Monstro Solid';
    src: url("../assets/fonts/Monstro Solid.otf") format('opentype');
}