@import "variables";
@import "forms";

body {
    background-color: $blue;
    box-sizing: border-box;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    color: $white;
    font-family: $font-secondary;
    margin-bottom: $spacing-md;
}

h1 {

    font-size: 40px;    

    @media (min-width: $screen-md) {
        font-size: 50px;    
    }

    @media (min-width: $screen-md) {
        font-size: 60px;
    }
}

h2 {

    font-size: 30px;    

    @media (min-width: $screen-md) {
        font-size: 40px;    
    }

    @media (min-width: $screen-md) {
        font-size: 50px;
    }
}

p, span, strong, li {
    font-family: $font-primary;
    line-height: 1.2;
}
