@import '../../../shared/variables.scss';
@include button;

.QRContainer {
    display: block;
    justify-content: center;
    align-items: center;
    height: min-content;

    .QR {
    }

    a {
        text-decoration: none;
    }

    .scanMe {
        margin: 0;
        font-family: "Monstro Solid";
        font-size: min(3.5vw, 3.5vh);
    }

    .center {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .expires {
        margin: 0;
        opacity: 1;
        font-family: "Futura PT";
        font-size: min(2vw, 2vh);
    }
}
