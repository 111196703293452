@import "../../shared/variables.scss";

@include button;


.player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1%;

    .potato {
        /*height: 100px;*/
    }

    .name {
        font-size: 2vh !important;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &.small {
            font-size: 1.5vh !important;
        }

        &.smaller {
            font-size: 1vh !important;
        }
    }

    .countdownContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        .removeButton {
            display: initial;
        }
    }
}

.fade {
    opacity: 0.25;
}

.removeButton {
    position: absolute;
    top: 3px;
    right: 5px;
    display: none;
    color: #B3434B;
    font-size: 20px;
}