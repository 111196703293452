@import '../../shared/variables.scss';

.gameVoteCard {
    @include image-background;
    @include disable-select;
    @include drop-shadow;
    border-radius: $rounding-sm;
    position: relative;
    width: auto;
    /*min-height: 150px;*/
    cursor: pointer;
    z-index: 10;
    transition: scale ease-in-out $speed-fast;
    margin-bottom: $spacing-sm;
    aspect-ratio: 14 / 9;
    max-width: 300px;
    margin: auto;
    /*overflow: hidden;*/

    @media (max-width: $screen-md) {
        margin-left: auto;
        margin-right: auto;
    }

    &.selected {
        border: solid 5px #E90F79;
    }

    .availableAddons {
        position: absolute;
        color: $off-white;
        height: fit-content;
        width: fit-content;
        bottom: 0;
        padding: 0px 10px;
        font-size: min(4vw, 2vh, 20px);
        border-radius: 0 20px 0 0;
        transition: transform $speed-fast;
    }

    .votesCounter {
        position: absolute;
        border-radius: 50%;
        top: -10px;
        left: -10px;
        z-index: 1;
        font-size: 30px;
        width: 40px;
        height: 40px;
        display: flex;

        span {
            display: inline-block;
            color: $white;
            margin: auto;
        }
    }

    img {
        @include abs-center;
        max-width: 90%;
        height: auto;
    }

    &:hover {
        scale: 1.1;
        /*z-index: 100;*/
    }
}