@import '../shared/variables.scss';

@include button;

@include spinner;


.form {
/*    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    margin: auto;*/
}

.activateModal {
    text-align: center;

    h2 {
        color: $black
    }

    color: $black;
    padding: $spacing-sm;

    .button {
        &:nth-of-type(1) {
            background-color: $hot-salmon;
            box-shadow: 0 9px #B3434B;
            
            &:hover {
                box-shadow: 0 5px #B3434B;
                transform: translateY(4px);
            }
        
            &:active {
                box-shadow: 0 1px #B3434B;
                transform: translateY(8px);
            }
        }
    }
}

.checkoutForm {

    h2 {
        color: $black
    }


    .checkoutTable {
        min-width: 100%;
        margin: 0;
        margin-bottom: 20px;

        tr {
            text-align: left;
            border-bottom: 2px solid $grey;
            height: 50px;

            &:last-of-type {
                border-bottom: 0;
            }

            th, td, strong, span {
                font-family: $font-secondary;
                font-weight: 500;
            }

            th {
                font-size: 22px;
                color: $blue;

                @media (min-width: $screen-md) {
                    font-size: 28px;
                }
            }

            td {

                @media (min-width: $screen-md) {
                    font-size: 20px;
                }

                .productLogo {
                    max-width: 100px;
                    height: auto;
                    margin: $spacing-xs 0;

                    @media (min-width: $screen-md) {
                        max-width: 150px;
                    }
                }

                .amountPicker {
                    display: flex;
                    align-items: center;

                    .quantity {
                        border: 2px solid $grey;
                        min-width: 70px;
                        font-size: 18px;
                        position: relative;
                        text-align: center;
                        border-radius: $rounded;

                        &.checkoutStep {
                            border: 0px;
                        }

                        .minus, .add {
                            position: absolute;
                            top: -2px;
                            height: calc(100% + 4px);
                            background: $blue;
                            color: $white;
                            border: none;
                            padding: 0 7px;
                        }

                        .minus {
                            left: -2px;
                            border-radius: $rounding-sm 0 0 $rounding-sm;
                        }

                        .add {
                            right: -2px;
                            border-radius: 0 $rounding-sm $rounding-sm 0;
                        }
                    }
                }

                &.deleteColumn {
                    width: 22px;

                    button {
                        background: none;
                        border: none;
                        padding: 0;
                        font-size: 30px;
                    }
                }
            }

            &.totalSavings {
                height: auto;
                color: darkgray;
                white-space: nowrap;
                border-bottom: 2px solid $grey;
            }

            &.total {
                height: auto;
                border-bottom: 0;
                border-top: 2px solid $grey;

                &:last-of-type {
                    border-bottom: 2px solid $grey;
                }

                .buffer {
                    width: 60%;
                }

                .bufferSmall {
                    width: 10%;
                }

                td {
                    /*padding: $spacing-xs 0;*/

                    &.title {
                        color: $hot-salmon;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .couponSection {
        min-width: 100%;
        margin: 0;
        margin-top: $spacing-sm;
        margin-bottom: $spacing-sm;
        padding: $spacing-xs;

        .title {
            font-family: $font-secondary;
            color: $hot-salmon;
            font-size: 26px;
            margin: unset;

            .icon {
                cursor: pointer;
            }
        }

        input {
            /*margin-bottom: $spacing-sm;*/
        }
    }
}

.productTable {
    min-width: 100%;
    margin-bottom: $spacing-sm;

    th, td, strong {
        font-family: $font-secondary;
        font-weight: 500;
    }

    th {
        font-size: 22px;
        color: $off-black;

        @media (min-width: $screen-md) {
            font-size: 28px;
        }

        border-bottom: 2px solid $grey;
    }

    .product {
        position: relative;

        p {
            margin-bottom: 0;
        }

        .disabledProduct {
            input[type=checkbox] {
                display: none;
            }
        }

        border-bottom: 2px solid $grey;

        &:last-of-type {
            border-bottom: none;
        }

        button {
            background: none;
            border: none;
            padding: 0;
        }

        td {
            padding: $spacing-xs 0;
        }

        .productName {
            width: 100px;
            font-family: $font-secondary;
            font-size: 18px;
            margin-bottom: 0;
        }

        .productCode {
            font-size: 14px;
            font-family: $font-primary;
        }


    }
}
.reviewsSection {
    @include image-background('../images/template/reviewsBg.png');
    max-height: 500px;

    .reviewWidth {
        max-width: 350px;

        @media (min-width: $screen-sm) {
            max-width: 450px;
            max-height: 300px;
        }
    }

    .carousel {
        overflow: hidden;
        max-height: 300px;
    }

    .reviewCard {
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
        border-radius: $rounding-sm;
        @include drop-shadow;
        min-height: 225px !important;
        overflow: hidden;

        .reviewTitle {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
        }

        .reviewBody {
            max-height: 140px;
            display: flex;
        }

        .reviewText {
            font-size: 18px;
            font-weight: bold;
            color: $black;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin: auto;
        }
    }
}

.buttonWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 20px;
}

.paymentMessage {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

.paymentElement {
    margin-bottom: 0px;
}

.band {
    padding: $spacing-md 0;

    @media (min-width: $screen-lg) {
        padding: $spacing-lg 0;
    }

    &.sm {
        padding: $spacing-sm 0;
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    form {
        /*width: 80vw;*/
        min-width: initial;
    }
}

