@import '../../../shared/variables.scss';
@include button;

.screenOverlay {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 26;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .screenOverlayBG {
        position: absolute;
        background-color: black;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: opacity ease-in-out 0.5s;
    }

    .modalContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .modal {
            position: relative;
            width: 550px;
            padding: min(2vw, 2vh);
            //background-color: #ffe52e;
            background-color: $off-white;
            border-radius: 30px;
            box-shadow: 0 0 min(2vw, 2vh) min(-0.2vw, -0.2vh) black;
            height: fit-content;
            transform: scale(0);
            transition: 0.5s cubic-bezier(.48,-0.45,.86,.49) transform;
            //border: solid 8px $blue;

            .buttonWrapper {
                position: relative;
                width: fit-content;
                margin: auto;

                .button {
                    width: fit-content;
                    height: 40px;
                    white-space: nowrap;
                    border-radius: 10px;
                    background-color: $hot-salmon;
                    color: white;
                    font-family: 'Bebas Neue';
                    font-size: 18px;
                    padding-top: 8px;
                    padding-bottom: 4px;
                }
            }

            .failedToJoin{
                color: $hot-salmon;
                margin:0;
            }
            
            h1 {
                color: black;
                font-size: 45px;
                text-align: center;
                padding: 20px;
                margin: 0 25px;
            }
        }

        @media screen and (max-width: 600px) {
            .modal {
                width: 90vw;
            }
        }
    }

    &.show {
        .screenOverlayBG {
            opacity: 0.5;
        }

        .modal {
            transform: scale(1);
            transition: 0.5s cubic-bezier(0,.84,.3,1.3) transform;
        }
    }
}
