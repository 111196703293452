@import '../../shared/variables.scss';
@include button;

.screenOverlay {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 26;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .screenOverlayBG {
        position: absolute;
        background-color: black;
        opacity: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        transition: opacity ease-in-out 0.5s;
    }

    .modalContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;

        .modal {
            position: relative;
            width: 550px;
            padding: min(2vw, 2vh);
            background-color: #ffe52e;
            border-radius: min(8vw, 40px);
            box-shadow: 0 0 min(2vw, 2vh) min(-0.2vw, -0.2vh) black;
            height: fit-content;
            transform: scale(0);
            transition: 0.5s cubic-bezier(.48,-0.45,.86,.49) transform;
            //border: solid 8px $blue;

            h1 {
                color: black;
                font-size: min(8vw, 45px);
                text-align: center;
                padding: 0px 20px min(2vw, 15px) 20px;
                margin: 0;
            }

            .background {
                position: relative;
                border-radius: 15px;
                background-color: $off-white;
                overflow: hidden;
                box-shadow: 0 0 5px 4px rgba(0,0,0,0.5);
                margin: 0px auto;
                width: calc(100% - 20px) !important;
            }

            .center {
                display: flex;
                justify-content: center;
            }

            .columbs {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 0.9fr;

                .columb {
                    width: 100%;
                }
            }

            .details {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 20px 10px;

                .wrapper {
                }

                h3 {
                    font-size: min(8vw, 26px);
                    color: $off-black;
                    margin: 0 !important;
                    text-align: left;
                }

                p {
                    font-size: min(6vw, 18px);
                    padding-top: 5px;
                    color: $off-black;
                    text-align: left;
                }
            }

            .priceBanner {
                position: absolute;
                right: -10px;
                top: -5px;
                // border-radius: ;
                font-size: 20px;
                background-color: $hot-salmon;
                -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
                clip-path: polygon(100% 0, 0 0, 100% 100%);
                padding: 15px 15px 40px 40px;
                border-top-right-radius: $rounding-sm;
                width: 110px;
                height: 90px;
                background: #10232A !important;


                span {
                    display: inline-block;
                    transform: rotate(40deg);
                    color: $white;
                }
            }

            .buttons {
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                margin-top: 20px;
                gap: min(3vw, 20px);

                .button {
                    font-size: min(5vw, 28px);
                    margin: 0 !important;
                }
            }


            .gameCard {
                width: 100%;
                //border-radius: 10px;
                padding: 0;
            }
        }

        @media screen and (max-width: 600px) {
            .modal {
                width: 90vw;
            }

            .background {
                max-width: 250px;
            }

            .columbs {
                grid-template-columns: 1fr !important;
            }

            .details {
                position: relative !important;
            }
        }
    }

    &.show {
        .screenOverlayBG {
            opacity: 0.5;
        }

        .modal {
            transform: scale(1);
            transition: 0.5s cubic-bezier(0,.84,.3,1.3) transform;
        }
    }
}
