@import "variables";

// Decided to create this file for global form styling, inputs and such

// Input Overrides
input[type=checkbox] {
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        border: 2px solid $grey;
        border-radius: 3px;
        background-color: $white;
    }

    &:checked::before {
        border: 2px solid $hot-salmon;
        background-color: $hot-salmon;
    }

    &:checked::after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 4px;
        left: 8px;
    }

    &:checked::before {
        border: 2px solid $hot-salmon;
        background-color: $hot-salmon;
    }

    &:checked::after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 4px;
        left: 8px;
    }

    &[disabled]{
        &::before {
            border-color: $white;
            background-color: $white;
        }
    }
}



