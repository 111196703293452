@import "../shared/variables.scss";
@import "../shared/core.scss";

@include button;

.band {
    padding: $spacing-md 0;

    @media (min-width: $screen-lg) {
        padding: $spacing-lg 0;
    }

    &.small {
        padding: $spacing-sm 0;
    }

    &.large {
        padding: $spacing-lg 0;
    }
}

.restrictWidth {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

}

.intro {
    display: flex;
    margin-bottom: $spacing-md;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-sm;

    p {
        font-weight: 700;
        font-size: 20px;

        @media (min-width: $screen-md) {
            font-size: 25px;
        }
    }


    img {
        max-width: 100px;
        height: auto;

        @media (min-width: $screen-md) {
            max-width: 150px;
        }
    }
}

.setupWizardSection {
    background-image: url(../images/yellowBg-v2.png);
    border-top-left-radius: $spacing-xs;
    border-top-right-radius: $spacing-xs;

    .youWillNeed {
        @include drop-shadow;
        background-color: $white;
        padding: $spacing-sm;
        margin-bottom: $spacing-sm;
        border-radius: $rounding-sm;

        h2 {
            font-family: $font-primary;
            font-size: 30px;
            font-weight: 700;
            color: $black;
            margin-bottom: $spacing-sm;
        }

        p {
            margin-bottom: 0;
        }

        .items {
            padding-left: $spacing-xs;
            padding-right: $spacing-xs;
            display: flex;
            gap: $spacing-xs;
            justify-content: space-between;
            align-items: center;
            font-weight: 800;
            flex-direction: column;

            @media (min-width: $screen-sm) {
                flex-direction: row;
            }

            img {
                margin-bottom: $spacing-xs;
            }

            .imageCol {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .guide {
        @include drop-shadow;
        background: rgba($white, 0.4);
        padding: $spacing-sm;
        border-radius: $rounding-sm;

        .subHeading {
            display: block;
            margin-bottom: $spacing-xs;
        }

        h1, h2, h3, h4, h5, h6 {
            color: $black;
            margin-bottom: $spacing-sm;
        }

        h2 + h3 {
            margin-top: -$spacing-sm
        }

        li {
            font-size: 17px;
            margin-bottom: $spacing-xs;
            font-size: 17px;

            p {
                margin-bottom: 0;
            }
        }

        .imageRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $spacing-sm;

            img {
                min-width: 150px;
                aspect-ratio: 2 / 1;

                @media (max-width: $screen-sm) {
                    display: none;
                }
            }
        }

        .notice {
            background: $hot-salmon;
            padding: $spacing-xs;
            color: $white;
            border-radius: $rounding-sm;
            margin-bottom: $spacing-sm;

            p {
                margin-bottom: 0;
            }

            &.imageNotice {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    min-width: 150px;
                }
            }
        }

        .allDone {
            display: flex;

            img {
                max-width: 100px;
                height: fit-content;
                margin-top: auto;
            }
        }
    }
}

.faqSection {
    @include image-background-fixed('../images/woodBg.png');
    border-bottom-left-radius: $spacing-xs;
    border-bottom-right-radius: $spacing-xs;

    .faqs {
        h3, p {
            color: $black;
            margin-bottom: 0;
        }
    }
}