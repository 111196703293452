@import '../../shared/variables.scss';

@include button;

@include spinner;

// This style sheet has become quite a mess

ul {
    padding-left: 0;
    li {
        list-style: none;
    }
}

.backgroundWrap {
    background-image: url(../../images/yellowBg-v2.png);
    padding: $spacing-lg 0;
}

.formWrap {
    background: $white !important;
    @include drop-shadow;
    padding: $spacing-md;
    max-width: 600px;
    margin: auto;
    border-radius: $rounding-sm;

    @media (min-width: $screen-xs) {
        min-width: 300px;    
    }
    
    @media (min-width: $screen-sm) {
        min-width: 400px;
    }

    @media (min-width: $screen-md) {
        min-width: 500px;
    }

    .formTitle {
        font-family: $font-secondary;
        color: $black;
        margin-bottom: $spacing-sm;
        font-weight: 500;
    }

    label, p {
        font-family: $font-primary;
    }

    label {
        font-size: 18px;
        font-weight: 500;
    }

    input {
        background-color: $input-color;
    }

    .passwordForget {
        color: $hot-salmon;
        font-size: 18px;
    }

    .passwordRequirements {
        margin: $spacing-sm 0;
        padding: $spacing-sm 0;
        border: 2px solid $input-color;
        border-radius: $rounding-sm;

        li {
            list-style: none;
            font-size: 14px;
        }
    }
}

.giftButton {
    gap: 10px;
    background-color: #0a8c44;
    box-shadow: 0 9px #096934;
    //on hover
    &:hover {
        box-shadow: 0 5px #096934;
    }

    img {
        height: 35px;

        //first image
    }
}

.checkoutForm {

    .checkoutTable {
        min-width: 100%;
        margin: 0;

        tr {
            text-align: left;
            border-bottom: 2px solid $grey;
            height: 50px;

            &:last-of-type {
                border-bottom: 0;
            }

            th, td, strong {
                font-family: $font-secondary;
                font-weight: 500;
            }

            th {
                font-size: 22px;
                color: $blue;

                @media (min-width: $screen-md) {
                    font-size: 28px;
                }
            }

            td {

                @media (min-width: $screen-md) {
                    font-size: 20px;
                }

                .productLogo {
                    max-width: 100px;
                    height: auto;
                    margin: $spacing-xs 0;

                    @media (min-width: $screen-md) {
                        max-width: 150px;
                    }
                }

                .amountPicker {
                    display: flex;
                    align-items: center;

                    .quantity {
                        border: 2px solid $grey;
                        min-width: 70px;
                        font-size: 18px;
                        position: relative;
                        text-align: center;
                        border-radius: $rounded;

                        &.checkoutStep {
                            border: 0px;
                        }

                        .minus, .add {
                            position: absolute;
                            top: -2px;
                            height: calc(100% + 4px);
                            background: $blue;
                            color: $white;
                            border: none;
                            padding: 0 7px;
                        }

                        .minus {
                            left: -2px;
                            border-radius: $rounding-sm 0 0 $rounding-sm;
                        }

                        .add {
                            right: -2px;
                            border-radius: 0 $rounding-sm $rounding-sm 0;
                        }
                    }
                }

                &.deleteColumn {
                    width: 22px;

                    button {
                        background: none;
                        border: none;
                        padding: 0;
                        font-size: 30px;
                    }
                }
            }

            &.total {
                td {
                    padding: $spacing-xs 0;

                    &:first-of-type {
                        color: $hot-salmon;
                        font-size: 26px;
                    }
                }
            }
        }
    }

    .couponSection {
        min-width: 100%;
        margin: 0;
        margin-top: $spacing-md;
        margin-bottom: $spacing-md;
        border: 2px solid $grey;
        border-radius: $rounding-sm;
        padding: $spacing-xs;

        .title {
            font-family: $font-secondary;
            color: $hot-salmon;
            font-size: 26px;
        }

        input {
            margin-bottom: $spacing-sm;
        }
    }
}

.productTable {
    min-width: 100%;
    margin-bottom: $spacing-sm;

    th, td, strong {
        font-family: $font-secondary;
        font-weight: 500;
    }

    th {
        font-size: 22px;
        color: $blue;

        @media (min-width: $screen-md) {
            font-size: 28px;
        }

        border-bottom: 2px solid $grey;
    }

    .product {
        position: relative;

        p {
            margin-bottom: 0;
        }

        .disabledProduct {
            input[type=checkbox] {
                display: none;
            }
        }

        border-bottom: 2px solid $grey;

        &:last-of-type {
            border-bottom: none;
        }

        button {
            background: none;
            border: none;
            padding: 0;
        }

        td {
            padding: $spacing-xs 0;
        }

        .productName {
            width: 100px;
            font-family: $font-secondary;
            font-size: 18px;
            margin-bottom: 0;
        }

        .productCode {
            max-width: 175px;
            font-size: 14px;
            font-family: $font-primary;
            word-wrap: break-word;
            padding: $spacing-xs;

            @media (min-width: $screen-md) {
                max-width: 250px;
            }
        }
    }
}

.buttonWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 20px;
}

.activateModal {
    text-align: center;

    h2 {
        color: $black;
        margin-bottom: 20px;
    }

    .bold {
        font-weight: bold;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
        color: $hot-salmon;
    }

    .balanced {
        text-wrap: pretty;
    }

    color: $black;
    padding: $spacing-sm;

    .button {
        &:nth-of-type(1) {
            background-color: $hot-salmon;
            box-shadow: 0 9px #B3434B;

            &:hover {
                box-shadow: 0 5px #B3434B;
                transform: translateY(4px);
            }

            &:active {
                box-shadow: 0 1px #B3434B;
                transform: translateY(8px);
            }
        }
    }
}



