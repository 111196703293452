@import "../shared/variables.scss";
@import "../shared/core.scss";

@include button;

.flexCenterXY {
    align-items: center;
}

.band {
    padding: $spacing-md 0;

    height: fit-content;

    @media (min-width: $screen-lg) {
        padding: $spacing-md 0;
    }

    &.sm {
        padding: $spacing-sm 0;
    }

    &.noTop {
        padding-top: 0;
    }
}


.rounded {
    border-radius: $spacing-xs;
}

.pageShopSingle {
    @include image-background-fixed;

    .backButton {
        display: flex;
    }

    .linkText {
        color: black;
        font-size: 20px;
    }

    .hero {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        min-width: 100vw;
        display: flex;
        justify-content: start;
        align-items: start;
        justify-content: center;
        align-items: center;
        left: 50%;
        /* top: 50%; */
        transform: translateX(-50%);
        padding: 0;

        .carouselItem {
            height: 200px;

            @media (min-width: $screen-sm) {
                height: 250px;
            }

            @media (min-width: $screen-md) {
                height: 400px;
            }

            @media (min-width: $screen-lg) {
                height: 500px;
            }

            img {
                width: 100%;
                object-fit: cover;
                display: block;
                margin: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .starRow {
        display: flex;
        align-items: center;
        gap: 2px;
        justify-content: center;
        margin-bottom: $spacing-xs;


        .star {
            width: 1em;
            height: 1em;
        }

        .starSum {
            /*cursor: pointer;*/
        }
    }

    .gameInfoContainer {
        min-height: 670px;
        height: fit-content;
        /*        @media (min-width: $screen-md) {
            height: 670px;
        }*/
    }

    .gamesList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        flex-wrap: nowrap;
        gap: 2%;
        margin-top: 25px;
        margin-bottom: $spacing-sm;

        .tinyCard {
            width: 32%;
            height: auto;
            border-radius: $rounding-sm;
            @include drop-shadow;
            transition: scale $speed-fast;

            &:hover {
                scale: 1.05;
                cursor: pointer;
                z-index: 1000;

                .gameCardOverlay {
                    display: flex;
                }
            }
        }

        @media (min-width: $screen-md) {
            flex-direction: column;
            margin-bottom: unset;
            height: 100%;
            gap: 20px;

            .tinyCard {
                width: auto;
                height: 32%;
            }
        }
    }


    .smallBanner {
        background-color: #FDE72F;
        padding: 8px;
        width: 100%;
        color: black;
        text-align: center;
        font-weight: 700;
        font-family: $font-primary;
        font-size: 20px;
    }

    .giftHint {
        background-color: #0a8c44;
        color: $white;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        gap: 10px;
        border-radius: $rounding-sm;
        margin-bottom: $spacing-xs;
        box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.5);

        p {
            font-size: 17px;
            font-weight: 600;
            margin: auto;
            text-align: center;
        }

        img {
            height: 36px;
        }

        @media (max-width: $screen-xl) {
            margin-left: auto;
            margin-right: auto;
            /*max-width: 800px;*/
        }
    }

    .gameInfoWrapper {
        background-color: $white;
        border-radius: $rounding-sm;
        padding: $spacing-md;
        max-width: 500px;
        max-height: 670px;
        height: 100%;
        position: relative;

        @media (max-width: $screen-lg) {
        }

        @media (max-width: $screen-xl) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $spacing-md;
            /*max-width: 800px;*/
        }

        @include drop-shadow;

        .button {
            padding: $spacing-xs;
            width: 200px;

            &:nth-of-type(2) {
                background-color: $hot-salmon;
                box-shadow: 0 9px #B3434B;

                &:hover {
                    box-shadow: 0 5px #B3434B;
                    transform: translateY(4px);
                }

                &:active {
                    box-shadow: 0 1px #B3434B;
                    transform: translateY(8px);
                }
            }
        }

        .gamePrice {
            font-family: $font-secondary;
            font-size: 50px;
            margin-bottom: $spacing-xs;
            font-weight: 600;
        }

        .strikeThroughPrice {
            position: absolute;
            right: -0.1px;
            top: -0.1px;
            // border-radius: ;
            font-size: 30px;
            background-color: $hot-salmon;
            -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
            clip-path: polygon(100% 0, 0 0, 100% 100%);
            padding: 17px 17px 45px 45px;
            border-top-right-radius: $rounding-sm;
            width: 140px;
            height: 120px;
            background: #10232B !important;


            span {
                display: inline-block;
                transform: rotate(40deg);
                color: $white;
                text-decoration: line-through;
            }
        }

        .gameInfo, .gameDescription {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .gameInfo {
            display: flex;
            justify-content: space-between;
            min-width: 100%;

            li {
                color: $white;
                font-size: 22px;
                font-family: $font-secondary;
                width: 33%;
            }

            .icon {
                scale: 0.85;

                img {
                    width: 100%;
                }
            }

            span {
                font-family: $font-secondary;
                color: $black;
            }
        }

        .gameDescription {
            text-align: left;
            padding-bottom: $spacing-sm;
            font-size: 20px;

            .descriptionHeader, .descriptionBody {
                font-size: 20px;
            }

            .descriptionHeader {
                font-size: 21px;
                font-family: $font-secondary;
                margin-bottom: 0;
            }

            .descriptionBody {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: $spacing-sm;
            }
        }
    }

    .subTitle {
        font-family: $font-primary;
        text-transform: none !important;
    }

    .howItWorksBox {
        width: fit-content;
        min-height: 100px;
        border: solid white 3px;
        border-radius: $spacing-sm;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: auto;

        .howItWorksBlock {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 800px;

            .number {
                margin-bottom: $spacing-xs;
                background-color: white;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                display: flex;


                p {
                    font-size: 40px;
                    font-family: $font-secondary;
                    color: #212B3D;
                    font-weight: 600;
                    margin: auto;
                }
            }

            .title {
                margin-bottom: 0;
            }

            .description {
                font-size: 20px;
                font-family: $font-primary;
                color: white;
                margin: auto;
                text-align: center;
            }
        }
    }

    .reviewsSection {
        @include image-background('../images/template/reviewsBg.png');
        max-height: 500px;

        .reviewWidth {
            max-width: 350px;

            @media (min-width: $screen-sm) {
                max-width: 450px;
                max-height: 300px;
            }
        }

        .carousel {
            overflow: hidden;
            max-height: 300px;
        }

        .reviewCard {
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xs;
            border-radius: $rounding-sm;
            @include drop-shadow;
            min-height: 225px !important;
            overflow: hidden;

            .reviewTitle {
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .reviewBody {
                max-height: 140px;
                display: flex;
            }

            .reviewText {
                font-size: 18px;
                font-weight: bold;
                color: $black;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                margin: auto;
            }
        }
    }


    .previewImages {
        @include drop-shadow;
        // border: 5px solid $black;
        border-radius: $rounding-sm;
        width: fit-content;
        /*min-width: 350px;*/

        @media (max-width: 1449px) {
            /*max-width: 576px;*/
            margin: 0 auto;
        }

        @media (max-width: $screen-xl) {
            /*max-width: 510px;*/
        }

        .imageRow {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            &.reverse {
                flex-direction: row-reverse;
            }

            img {


                &.mobilePreview {
                    max-width: 93.75px;

                    @media (min-width: $screen-lg) {
                        max-width: 131.25px;
                        height: 240px;
                    }

                    @media (min-width: $screen-xl) {
                        max-width: 150px;
                        height: 250px;
                    }

                    @media (min-width: calc($screen-xl + 250px)) {
                        max-width: 187.5px;
                        height: 275px;
                    }
                }

                &.desktopPreview {
                    max-width: 250px;

                    @media (min-width: $screen-lg) {
                        max-width: 350px;
                        height: 240px;
                    }

                    @media (min-width: $screen-xl) {
                        max-width: 450px;
                        height: 250px;
                    }

                    @media (min-width: calc($screen-xl + 250px)) {
                        max-width: 500px;
                        height: 275px;
                    }
                }
            }
        }

        &.mobile {

            @media (max-width: $screen-xl) {
                margin: auto;
                max-width: 800px;

                .imageRow {

                    img {
                        min-width: 100%;
                        max-width: 100%;
                        height: auto;
                    }

                    &.singleDesktopRow {
                        div {
                            flex: 1;
                        }
                    }

                    &.doubleColRow {
                        div {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }


    .gameDetails {

        .gameDetailsInner {
            background-color: #394050;
            border-radius: $spacing-sm;

            .icon {
                width: 35%;
                transform: translateY(-25%);
                @include drop-shadow;
                border-radius: $rounding-sm;
            }

            .description {
                color: #fff;
                font-size: 20px;
                width: 100%;
                margin: auto;
                padding: 0 8%;
            }

            .imageBlock {
                display: flex;
                gap: $spacing-xs;
                justify-content: center;


                .img {
                    border-radius: 5px;
                    border: solid 3px white;
                    overflow: hidden; /* Ensures the rounded corners clip the background */
                    background-size: cover;

                    img {
                        display: block; /* Make sure the image is treated as a block element */
                        width: 100%; /* Ensure the image takes up the full width of its container */
                        height: auto; /* Maintain the aspect ratio of the image */
                        border-radius: 10px; /* Apply the same border-radius as the parent div */
                        opacity: 0;
                    }

                    &.desktopImg {
                        /*width: 75%;*/
                    }

                    &.mobileImg {
                        /*width: 25%;*/
                    }
                }
            }
        }
    }
}


.thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    /* padding: 27px; */
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
}

