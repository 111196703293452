@import "../shared/variables.scss";
@import "../shared/core.scss";

.appNotification {
    background-color: #029480;
    padding: 8px;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: $font-primary;
    font-size: 16px;
}